import React, { useRef, useState, useEffect } from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

import { TextWithUrl } from "../../../types/types"

interface MenuModalProps {
    tabArray: Array<TextWithUrl>
    closeIcon: any
    backgroundColor: string
    windowWidth: number
    open: boolean
    onClose: () => void


}
const MenuModal = (props: MenuModalProps) => {

    const modalRef = useRef(null)
    const { open, onClose, closeIcon, windowWidth, backgroundColor, tabArray } = props
    const [translateValue, setTranslateValue] = useState(modalRef?.current?.offsetWidth)
    useEffect(() => {
        setTranslateValue(modalRef.current.offsetWidth)
    }, [windowWidth])
    useEffect(() => {
        if (props.open === true) {
            setTranslateValue(-modalRef.current.offsetWidth)
        }

    }, [open])
    const handleClose = () => {
        setTranslateValue(modalRef.current.offsetWidth)
        onClose()
    }

    return <div style={{ display: "flex", flexDirection: "column", position: "fixed", top: "0px", right: `-${translateValue}px`, transform: `translateX(${translateValue}px)`, transition: 'transform 0.45s ease-out', width: "100%", backgroundColor: backgroundColor ?? "#1f1b90", paddingTop: "10px" }} ref={modalRef} className="header-modal-container">
        <div className="header-modal-inner-container">
            <GatsbyImage image={props.closeIcon} alt="" onClick={() => handleClose()} className="header-close-icon-field" />
            {/* <img src={"http://gatsby.ludojoy.com/wp-content/uploads/2021/09/close-icons.png"} className="header-close-icon-field" /> */}
        </div>
        {tabArray.map((tab, index) => {
            return <a href={tab.url} className={"tabText tab-overlay-row"} style={{ color: tab.text.color ?? "#FFF" }} key={index}><p className="header-p-tag">{tab.text.text}</p></a>
        })}
    </div>
}

export default MenuModal