export const DIMENS = {
	W150: 150
};

export const COLORS = {
	ALERT: {
		SUCCESS: '#1F8000',
		ERROR: '#892C21'
	}
};

export const ANIMATION_TYPES = {
	FADE_IN: 'fadeIn',
	TRANSLATE_HORIZONTALLY: 'translateHorizontally'
};

export const PROMISE_SUCCESS = {
	status: 'fulfilled',
	statusCode: 200
};

export enum CASE {
	UPPERCASE,
	LOWERCASE,
	NOCASE
}
export const ANALYTICS_CONSTANTS = {
	EVENT: 'eventTracking',
	APK_DOWNLOAD_EVENT: {
		CATEGORY: 'download',
		ACTION: 'apk download click',
		LABEL: 'apk download'
	},
	IOS_APP_STORE_EVENT: {
		CATEGORY: 'download',
		ACTION: 'ios appstore click',
		LABEL: 'ios appstore click'
	},
	PAGE_LOAD_EVENT: {
		CATEGORY: 'newWebsite',
		ACTION: 'new website load',
		LABEL: 'new website load'
	},
	CATEGORY_POPUP_DOWNLOAD: 'popup download',
	THANK_YOU_PAGE_NAME: 'thankyou',
	HOME_PAGE_NAME: 'homepage',
	FB_EVENT_HOME_PAGE: 'PageView',
	FB_EVENT_THANKYOU_PAGE: 'Lead'
};

export enum OperatingSystem {
	ANDROID,
	IOS,
	OTHERS
}
export const THANKYOU_PAGE_PATH = '/thankyou';

export const MOBILE_BREAKPOINT_WIDTH = 600;
