import React from 'react'

import { Helmet } from "react-helmet"

interface SEOProps {
    title: string;
    keyWords: string
    canonical: string
    description: string
    favIcon: string
    structuredSchemaData: Array<any>
}
const SEOComponent = (props: SEOProps) => {
    const { title, keyWords, canonical, description, favIcon, structuredSchemaData } = props
    return (

        <Helmet>
            <meta name="description" content={description} />
            <title>{title}</title>
            <meta name="keywords" content={keyWords} />
            <link rel="canonical" href={canonical}></link>
            <link rel="icon" type="image/png" href={favIcon} sizes="16x16" />
            {
                structuredSchemaData?.map((structuredJson, index) => {
                    return <script type="application/ld+json">
                        {structuredJson?.structuredjson}
                    </script>
                })
            }
        </Helmet >
    )
}
export default SEOComponent

