import React from "react";
export const isEmptyOrNull = (string: string) => {
    if (string === "" || string === undefined)
        return true;
    return false
}

export const breakLines = (text: string) => {
    const lineArray = text.split("\\n")
    return lineArray.map((element, index) => {
        return <React.Fragment key={index}>{element}{index < lineArray.length - 1 ? <br /> : null}</ React.Fragment>
    })
}

