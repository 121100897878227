
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

import FooterMenu from "./FooterMenu"
import "./footer.modules.css"
import useQuery from "./useQuery"
import { FooterDataProps } from "../../../types/types"


const Footer = () => {
  const data = useQuery()
  const { rightTitle, socialMediaIconArray, paymentsIconArray, pagesArray, copyRightText, backgroundImage, logoImage, iconBackgroundColor } = data

  return (

    <div className="footer-background-image-container">
      <GatsbyImage image={backgroundImage} alt="" className="footer-background-image" />
      <FooterContainer  {...data} />
    </div >

  )
}
const FooterContainer = (props: FooterDataProps) => {
  const { rightTitle, socialMediaIconArray, paymentsIconArray, pagesArray, copyRightText, backgroundImage, logoImage, iconBackgroundColor, backgroundColor } = props
  return (
    <div className="footer-container footer-data-container-grid" style={{ backgroundColor: backgroundColor }}>
      <div className="footer-left" >
        <div className="footer-logo"><GatsbyImage image={logoImage.imageFile} className="footer-logo-image" alt={logoImage.altText} /></div>

        <div className="social-images-container">
          {socialMediaIconArray.map((icon, index) => {
            return <div style={{ backgroundColor: iconBackgroundColor }} className="social-media-image-div" key={index}>
              <a href={icon.url}><GatsbyImage image={icon.image.imageFile} alt={icon.image.altText} className="social-media-image-field" /></a>
            </div>
          })}
        </div>
        <div className="page-list">
          <span>
            <FooterMenu pagesArray={pagesArray} />
          </span>
        </div>
        <div className="copyright-text">
          <p style={{ color: copyRightText.color }} className="footer-p-tag">{copyRightText.text}</p>
        </div>
      </div>
      <div className="footer-right">
        <div className="payment-label-text">

          <p style={{ color: rightTitle.color }} className="right-title-text footer-p-tag">{rightTitle.text}</p>
        </div>
        <div className="image-array-payments">
          {
            paymentsIconArray.map((icon, index) => {
              return <GatsbyImage image={icon.imageFile} alt={icon.altText} className="payment-icon" key={index} />
            })
          }
        </div>
        <div className="page-list-right">
          <span ><FooterMenu pagesArray={pagesArray} /></span>
        </div>
        <div className="copyright-text-right">
          <p style={{ color: copyRightText.color }} className="footer-p-tag">{copyRightText.text}</p>
        </div>
      </div>
    </div>

  )
}

export default Footer

