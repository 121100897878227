import _map from 'lodash/map';

import { graphql, useStaticQuery } from 'gatsby';

interface CommonPageData {
  gtmId: string;
  analyticsPrefix: string;
}

const processData = (data: any): CommonPageData => {
  return {
    gtmId: data?.allWpPage?.edges[0]?.node?.gtmCredentials.gtmid,
    analyticsPrefix: data?.allWpPage?.edges[0]?.node?.gtmCredentials.analyticsprefix ?? ''
  };
};

export const gtmInitializationScript = (gtmId: string): string => {
  return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${gtmId}');`;
};

export const useQuery = () => {
  const data = processData(
    useStaticQuery(graphql`
			{
				allWpPage(filter: { uri: { eq: "/gtm/" } }) {
					edges {
						node {
							id
							gtmCredentials {
								gtmid
								analyticsprefix
							}
						}
					}
				}
			}
		`)
  );
  return data;
};

export default useQuery;
