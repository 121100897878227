import React from 'react';

interface IPageContext {
	page: string;
	data: any;
	downloadLink: string;
	isAkamai: boolean;
}

const PageContext = React.createContext<IPageContext>({} as IPageContext);

export default PageContext;
