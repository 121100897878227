import React from 'react';

import AnalyticsContext from './analyticsContext';

interface AnalyticsProviderProps {
	children: any;
	data: any;
}
const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({ children, data }) => {
	return <AnalyticsContext.Provider value={data}>{children}</AnalyticsContext.Provider>;
};
export default AnalyticsProvider;
