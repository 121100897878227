import React from 'react';

interface IAnalyticsContext {
	data: any;
	downloadLink: string;
}

const AnalyticsContext = React.createContext<IAnalyticsContext>({} as IAnalyticsContext);

export default AnalyticsContext;
