import { graphql, useStaticQuery } from "gatsby";

import { Button, StickyBottomData, WinningText } from "../../../types/types";

const processStickyBottomData = (data: any): StickyBottomData => {
  const myData = data.allWpPage.edges[0].node.stickybottom.downloadbuttondata
  const winningTextData = data?.allWpPage?.edges[0]?.node?.stickybottom?.imagewithtext
  const button = myData?.button
  const winningText: WinningText = {
    text: winningTextData?.description,
    image: { imageFile: winningTextData?.image?.imagefile?.localFile?.url, altText: winningTextData?.image?.alttext }
  }
  const joiningBonusText = {
    text: button.bonustext.text,
    color: button.bonustext.color
  }
  const buttonData: Button = {
    buttonColor: button?.buttoncolor,
    buttonText: button?.buttontext,
    buttonIosLink: myData?.buttonioslink,
    buttonAndroidLink: myData?.buttonandroidlink,
    buttonAndroidImage: { imageFile: myData?.buttonandroidimage.imagefile?.localFile?.childImageSharp?.gatsbyImageData, altText: myData?.buttonandroidimage.alttext },
    buttonIosImage: { imageFile: myData?.buttoniosimage.imagefile?.localFile?.childImageSharp?.gatsbyImageData, altText: myData?.buttoniosimage.alttext },
    buttonBackgroundImage: { imageFile: button?.buttonbackground?.imagefile?.localFile?.childImageSharp?.gatsbyImageData, altText: button?.buttonbackground?.alttext },
    buttonColorStart: button?.buttoncolorstart,
    buttonColorEnd: button?.buttoncolorend
  }
  const stickyBarData: StickyBottomData = {
    backgroundColor: myData.background.color,
    backgroundImage: myData?.background?.image?.localFile?.childImageSharp?.gatsbyImageData,
    downloadButtonData: {
      button: buttonData
    },
    joiningBonusText: joiningBonusText,
    winningText: winningText
  }
  return stickyBarData
}


const useQuery = (): StickyBottomData => {
  const data = processStickyBottomData(
    useStaticQuery(graphql`
        {
            allWpPage(filter: {uri: {eq: "/sticky-bottom/"}}) {
              edges {
                node {
                  id
                  uri
                  stickybottom {
                    downloadbuttondata {
                      background {
                        color
                      }
                      button {
                        buttoncolor
                        buttoncolorstart
                        buttoncolorend
                        buttontext {
                          color
                          text
                        }
                        buttonbackground {
                          alttext
                          imagefile {
                            localFile {
                              childImageSharp {
                                gatsbyImageData
                              }
                            }
                          }
                        }
                        bonustext {
                          text
                          color
                        }
                      }
                      
                      buttonandroidimage {
                        alttext
                        imagefile {
                          localFile {
                            childImageSharp {
                              gatsbyImageData
                            }
                          }
                        }
                      }
                      buttoniosimage{
                        alttext
                        imagefile {
                          localFile {
                            childImageSharp {
                              gatsbyImageData
                            }
                          }
                        }
                      }
                      buttonandroidlink
                      buttonioslink
                    }
                    imagewithtext {
                      image {
                        alttext
                        imagefile {
                          localFile {
                            url
                          }
                        }
                      }
                      description {
                        text
                      }
                    }
                  }
                }
              }
            }
          }   
		`)
  );
  return data;
};

export default useQuery;


