import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import SEOComponent from '../fields/seoField';
import Footer from '../sections/footer';
import Header from '../sections/header';
import StickyBottomBar from '../sections/stickyDownloadButton';
import { SEOProps } from '../../types/types';
import useQuery, { gtmInitializationScript } from './useQuery';
import PageContext from '../../context/page/pageContext';
import AnalyticsProvider from '../../context/analytics/AnalyticsProvider';

import { ANALYTICS_CONSTANTS } from '../../constants/index';

interface CommonRendererProps extends SEOProps {
	children: any;
}
const CommonPageRenderer = (props: CommonRendererProps) => {
	const { fbEventName, pageName } = props;
	const { data, page } = useContext(PageContext);
	const seoFields = data?.allWpPage?.edges?.[0]?.node?.[page]?.[`${page}Seofields`];
	const { gtmId, analyticsPrefix } = useQuery();

	useEffect(() => {
		let dataLayer = window.dataLayer || [];
		dataLayer.push({
			event: analyticsPrefix + ANALYTICS_CONSTANTS.EVENT,
			category: analyticsPrefix + ANALYTICS_CONSTANTS.PAGE_LOAD_EVENT.CATEGORY,
			action: analyticsPrefix + ANALYTICS_CONSTANTS.PAGE_LOAD_EVENT.ACTION + ` ${pageName}`,
			label: analyticsPrefix + ANALYTICS_CONSTANTS.PAGE_LOAD_EVENT.LABEL
		});
	}, []);
	return (
		<AnalyticsProvider data={analyticsPrefix}>
			<React.Fragment>
				<noscript>
					<iframe
						src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
						height='0'
						width='0'
						className='gtm-iframe'></iframe>
				</noscript>
				<Helmet>
					<script>{gtmInitializationScript(gtmId)}</script>
				</Helmet>
				<SEOComponent
					description={seoFields?.description}
					keyWords={seoFields?.keywords}
					title={seoFields?.title}
					canonical={seoFields?.canonicaltag}
					favIcon={seoFields?.favicon?.localFile?.url}
					structuredSchemaData={seoFields?.structuredschemadata}
				/>
				<Header />
				{props.children}
				<Footer />
				{pageName !== 'thankyou' ? <StickyBottomBar /> : null}
			</React.Fragment>
		</AnalyticsProvider>
	);
};

export default CommonPageRenderer;
